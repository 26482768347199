import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import useState from "hooks/useState";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { SUCCESS } from "shared/remote-data";
import Header from "components/header";
import HeaderLogo from "components/header-logo";
import TextTitle from "components/text-title";
import LogoutButton from "components/logout-button";
import Fab from "components/fab-button";
import SubTitle from "components/sub-title";
import PlayIcon from "@material-ui/icons/ArrowRight";
import DownloadIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { baseUrl } from "shared/constants";

const useStyles = makeStyles({
  listItem: {
    "&:first-child": {
      borderTop: "1px solid rgba(0, 0, 0, 0.117647)"
    }
  }
});

const Overview = () => {
  const history = useHistory();
  const [{ routes, token }] = useState();

  const finishedRoutes = useMemo(() => {
    if (routes.state === SUCCESS) {
      return routes.value.filter(({ status: { code } }) => code === 9);
    }
    return [];
  }, [routes]);

  const unfinishedRoutes = useMemo(() => {
    if (routes.state === SUCCESS) {
      return routes.value.filter(({ status: { code } }) => code !== 9);
    }
    return [];
  }, [routes]);

  const classes = useStyles();

  // actions

  const finishedRouteCallback = id => {
    const win = window.open(
      `${baseUrl}/export/${id}.pdf?apiKey=${token}`,
      "_blank"
    );
    win.focus();
  };

  const handleUnfinishedRoute = id => {
    history.push(`/map/${id}`, { from: "overview" });
  };

  const handleNewRoute = event => {
    history.push("/new");
  };

  return (
    <>
      <Header>
        <HeaderLogo />
        <LogoutButton />
      </Header>
      <Container maxWidth="sm" disableGutters>
        <Box mt={4} mb={3} ml={2} mr={2}>
          <TextTitle>Schouwoverzicht</TextTitle>
        </Box>
        <Box ml={2} mr={2}>
          <SubTitle>Af te ronden schouwen</SubTitle>
        </Box>
        <List>
          {unfinishedRoutes.length === 0 ? (
            <ListItem>
              <ListItemText>Er zijn geen af te ronden schouwen.</ListItemText>
            </ListItem>
          ) : (
            unfinishedRoutes.map(r => (
              <ListItem
                button
                divider
                key={r.id}
                className={classes.listItem}
                onClick={() => handleUnfinishedRoute(r.id)}
              >
                <ListItemText>{r.name}</ListItemText>
                <ListItemIcon>
                  <PlayIcon />
                </ListItemIcon>
              </ListItem>
            ))
          )}
        </List>
        <Box ml={2} mr={2} mt={4}>
          <SubTitle>Voltooide schouwen</SubTitle>
        </Box>
        <Box mb={8}>
          <List>
            {finishedRoutes.length === 0 ? (
              <ListItem>
                <ListItemText>Er zijn geen voltooide schouwen.</ListItemText>
              </ListItem>
            ) : (
              finishedRoutes.map(({ name, id }) => (
                <ListItem
                  button
                  divider
                  key={id}
                  className={classes.listItem}
                  onClick={() => finishedRouteCallback(id)}
                >
                  <ListItemText>{name}</ListItemText>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<DownloadIcon />}
                  >
                    Pdf
                  </Button>
                </ListItem>
              ))
            )}
          </List>
        </Box>
      </Container>
      <Fab onClick={handleNewRoute}>Nieuwe schouw aanmaken</Fab>
    </>
  );
};

export default Overview;
