import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  text: {
    fontWeight: "bold"
  }
});

const SubTitle = props => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.text}
      variant="subtitle1"
      gutterBottom
      {...props}
    />
  );
};

export default SubTitle;
