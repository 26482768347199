import React from "react";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: 0,
    right: 0,
    margin: "0 auto",
    width: `calc(100% - ${theme.spacing(4)}px)`,
    maxWidth: "600px"
  }
}));

const MyFab = props => {
  const classes = useStyles();

  return (
    <Fab
      variant="extended"
      color="primary"
      className={classes.fab}
      {...props}
    />
  );
};

export default MyFab;
