import React from "react";
import { useHistory } from "react-router-dom";
import useState from "hooks/useState";
import useSnackbar from "hooks/useSnackbar";
import {
  useFormElement,
  useFormValue,
  isEmpty,
  isNotEmail
} from "hooks/useForm";
import Header from "components/header";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "components/textfield";
import Button from "components/button";
import HeaderLogo from "components/header-logo";
import TextTitle from "components/text-title";
import {useAPI} from "../shared/requests";

const Password = () => {
  const history = useHistory();
  // eslint-disable-next-line no-empty-pattern
  const [{ }, dispatch] = useState();
  const [open] = useSnackbar();
  const api = useAPI();

  // form values

  const [disabled, setDisabled] = useFormValue("form-password-disabled", false);

  const [email, validateEmail] = useFormElement(
    "form-password-email",
    "E-mailadres",
    "",
    [
      { validation: isEmpty, message: "E-mailadres mag niet leeg zijn" },
      { validation: isNotEmail, message: "E-mailadres moet geldig zijn" }
    ]
  );

  const resetFields = () =>
    dispatch({ type: "FORM_RESET_BATCH", payload: "form-password--" });

  // form post

  const postForm = () => {
    api.postPasswordReset(email.value)
      .then(
        res => {
          history.push("/login");
          open("Email is verzonden, indien bekend.", "success");
        },
        err => {
          console.log("error - password reset", err);
        }
      )
      .finally(() => setDisabled(false));
  };

  // form validation

  const handleFormSubmit = event => {
    event.preventDefault();

    const validationResult = [validateEmail()].filter(i => i != null);
    if (validationResult.length === 0) {
      setDisabled(true);
      postForm();
    }
  };

  // button actions

  const handleCancelButton = () => {
    resetFields();
    history.push("/login");
  };

  return (
    <>
      <Header>
        <HeaderLogo />
      </Header>
      <Container maxWidth="sm">
        <Box mt={4} mb={3}>
          <TextTitle>Inloggen</TextTitle>
        </Box>
        <form autoComplete="off" noValidate onSubmit={handleFormSubmit}>
          <TextField
            placeholder="Vul hier je e-mailadres in"
            type="email"
            required
            {...email}
            disabled={disabled}
          />
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <Button
                  disabled={disabled}
                  variant="outlined"
                  color="default"
                  onClick={handleCancelButton}
                >
                  Annuleren
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button disabled={disabled} type="submit">
                  Inloggen
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  );
};

export default Password;
