import {useState as localState} from 'react';
import useMount from "hooks/useMount";
import useState from "hooks/useState";

export const useFormValue = (id, value) => {
  const [{ formData }, dispatch] = useState();
  const current = formData[id] === undefined ? { value, initialValue: value } : formData[id];

  // initialize value
  useMount(() => {
    dispatch({
      type: "FORM_DATA_SET",
      payload: {
        id,
        value: current
      }
    });
  });

  const setValue = v =>
    dispatch({
      type: "FORM_DATA_UPDATE_VALUE",
      payload: {
        id,
        field: "value",
        value: v
      }
    });

  return [current.value, setValue];
};

export const useFormElement = (id, label, value, validations = []) => {
  const [{ formData }, dispatch] = useState();
  const current = formData[id] === undefined
      ? {value, initialValue: value, label, error: null, validations}
      : formData[id];

  // initialize value
  useMount(() => {
    dispatch({
      type: "FORM_DATA_SET",
      payload: {
        id,
        value: current
      }
    });
  });

  // update value
  const onChange = ({ target: { value, id } }) => {
    dispatch({
      type: "FORM_DATA_UPDATE_VALUE",
      payload: {
        id,
        field: "value",
        value
      }
    });
  };

  // run validation
  const validate = () => {
    let result = null;

    for (let i = 0; i < current.validations.length; i++) {
      const { validation, message } = current.validations[i];
      if (validation(formData[id].value)) {
        result = message;
        break;
      }
    }

    dispatch({
      type: "FORM_DATA_UPDATE_VALUE",
      payload: {
        id,
        field: "error",
        value: result
      }
    });

    return result != null ? id : result;
  };

  return [
    {
      id,
      label: current.error != null ? current.error : current.label,
      value: current.value,
      onChange,
      error: current.error != null
    },
    validate
  ];
};


export const useLocalFormElement = (id, label, value, validations = []) => {
  const [current, setLocalState] = localState(value);
  const [error, setError] = localState(null);

  // update value
  const onChange = ({ target: { value } }) => setLocalState(value);

  // run validation
  const validate = () => {
    let result = null;

    for (let i = 0; i < validations.length; i++) {
      const { validation, message } = validations[i];
      if (validation(current)) {
        result = message;
        break;
      }
    }

    setError(result);
    return result != null ? id : result;
  };

  return [
    {
      id,
      value: current,
      onChange,
      label: error != null ? error : label,
      error: error != null
    },
    validate
  ];
};

export const isEmpty = value => value.trim() === "";
export const atLeast3 = value => value.length < 3;
export const isNotEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(String(email).toLowerCase());
};
