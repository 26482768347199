import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/logo.png";

const useStyles = makeStyles((theme) => ({
  root: ({ active }) => {
    return {
      backgroundColor: theme.palette.primary["500"],
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 9999999,
      transition: "opacity 500ms ease",
      opacity: active ? 1 : 0,
      pointerEvents: active ? "normal" : "none",
      display: "flex",
      padding: "0 16px",
      alignItems: "center",
    };
  },
  image: {
    width: "100%",
    maxWidth: "304px",
    margin: "0 auto",
  },
}));

const Splash = ({ active }) => {
  const classes = useStyles({ active: active });

  return (
    <div className={classes.root}>
      <img className={classes.image} src={logo} alt="PVO Logo" />
    </div>
  );
};

export default Splash;
