import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/logo.png";

const useStyles = makeStyles({
  logo: {
    height: "27px",
  },
});

const HeaderLogo = () => {
  const classes = useStyles();

  return <img src={logo} className={classes.logo} alt="PVO logo" />;
};

export default HeaderLogo;
