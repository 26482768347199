// image logic
const $maxWidth = 800;

export function getBase64Image(img) {
  var canvas, context, scale, width, height, imageWidth;
  canvas = document.createElement("canvas");
  context = canvas.getContext("2d");
  // scale logic
  if (img.width >= img.height) {
    width = Math.min($maxWidth, img.width);
    scale = width / img.width;
    canvas.width = img.width * scale;
    canvas.height = img.height * scale;
    context.drawImage(img, 0, 0, width, img.height * scale);
  } else {
    scale = Math.min($maxWidth, img.height) / img.height;
    width = img.height * scale;
    height = img.width * scale;
    imageWidth = height * (img.width / img.height);
    canvas.width = width;
    canvas.height = height;
    context.fillStyle = "white";
    context.fillRect(0, 0, width, height);
    context.drawImage(
      img,
      Math.round((width - imageWidth) / 2),
      0,
      imageWidth,
      height
    );
  }
  var dataURL = canvas.toDataURL("image/png");
  return dataURL;
}

export function previewImage(file, callback) {
  var reader = new FileReader();
  reader.onload = function(e) {
    var preview = document.createElement("img");
    preview.src = e.target.result;
    preview.onload = function() {
      callback(getBase64Image(preview));
    };
  };
  reader.readAsDataURL(file);
}
