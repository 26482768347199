import React, { useEffect, useState as localState } from "react";
import {useAPI} from "../shared/requests";
import {
  setLoading,
  NOT_ASKED,
  SUCCESS
} from "shared/remote-data";
import useState from "hooks/useState";
import { useLocalFormElement, isEmpty } from "hooks/useForm";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "components/button";
import Box from "@material-ui/core/Box";
import SubTitle from "components/sub-title";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Header from "components/header";
import TextTitle from "components/text-title";
import TextField from "components/textfield";
import { baseUrl } from "shared/constants";
import { useHistory } from "react-router-dom";

const Edit = ({ route, handleClose }) => {
  const history = useHistory();
  const [{ token, groups }, dispatch] = useState();
  const [categoryError, setCategoryError] = localState("");
  const api = useAPI();

  // make sure to get groups
  useEffect(() => {
    if (groups.state === NOT_ASKED) {
      dispatch({ type: "GROUPS_SET", payload: setLoading() });
      api.getGroups();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups.state, history, token]);

  // form values
  const [disabled, setDisabled] = localState(false);
  const [name, validateName] = useLocalFormElement(
    "form-edit-title",
    "Titel",
    route.name,
    [{ validation: isEmpty, message: "Titel mag niet leeg zijn" }]
  );
  const [description] = useLocalFormElement(
    "form-edit-description",
    "Omschrijving",
    route.description,
    []
  );
  const [postalCode, validatePostalCode] = useLocalFormElement(
    "form-edit-postal-code",
    "Postcode",
    route.postal_code,
    [{ validation: isEmpty, message: "Postcode mag niet leeg zijn" }]
  );
  const [city, validateCity] = useLocalFormElement(
    "form-edit-city",
    "Plaats",
    route.city,
    [{ validation: isEmpty, message: "Plaats mag niet leeg zijn" }]
  );
  const [groupId, setGroupId] = localState(route.category.id);

  const handleGroupSelect = ({ target: { value } }) => {
    setCategoryError("");
    setGroupId(parseInt(value));
  };

  const handleSubmitGegevens = event => {
    event.preventDefault();

    const validationResult = [
      validateName(),
      validatePostalCode(),
      validateCity()
    ].filter(i => i !== null);

    if (validationResult.length === 0) {
      setDisabled(true);

      const updatedResult = {
        id: route.id,
        name: name.value,
        description: description.value,
        postal_code: postalCode.value,
        city: city.value,
        category_id: parseInt(groupId),
        category: { ...route.category, id: parseInt(groupId) }
      };

      const localResult = {
        ...route,
        ...updatedResult,
        category: { ...route.category, id: parseInt(groupId) }
      };

      fetch(`${baseUrl}/route?apiKey=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(updatedResult)
      })
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then(result => {
          dispatch({
            type: "ROUTES_UPDATE",
            payload: localResult
          });
          handleClose();
        })
        .catch(err => history.replace("/"))
        .finally(() => setDisabled(false));
    }
  };

  return (
    <>
      <Header />
      <Container maxWidth="sm" disableGutters>
        <Box ml={2} mr={2}>
          <Box mt={4} mb={3}>
            <TextTitle>Schouw aanpassen</TextTitle>
          </Box>
        </Box>
        <Box ml={2} mr={2}>
          <form noValidate autoComplete="off" onSubmit={handleSubmitGegevens}>
            <TextField
              placeholder="Vul hier de titel in"
              type="text"
              required
              margin="normal"
              {...name}
              disabled={disabled}
            />
            <TextField
              placeholder="Vul hier de omschrijving in"
              type="text"
              multiline
              margin="normal"
              rows={3}
              {...description}
              disabled={disabled}
            />
            <TextField
              placeholder="Vul hier de postcode in"
              type="text"
              required
              margin="normal"
              {...postalCode}
              disabled={disabled}
            />
            <TextField
              placeholder="Vul hier de plaats in"
              type="text"
              required
              margin="normal"
              {...city}
              disabled={disabled}
            />
            <Box mb={2}>
              <SubTitle>Kies een gebied*</SubTitle>
              <FormControl
                variant="filled"
                fullWidth
                error={categoryError !== ""}
              >
                <InputLabel htmlFor="form-route-settings-category">
                  {categoryError}
                </InputLabel>
                <Select
                  native
                  required
                  value={groupId}
                  disabled={disabled || groups.state !== SUCCESS}
                  onChange={handleGroupSelect}
                >
                  {groups.state === SUCCESS &&
                    groups.value.map(({ id, title }) => (
                      <option key={id} value={id}>
                        {title}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={4}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Button
                    disabled={disabled}
                    variant="outline"
                    onClick={() => handleClose()}
                  >
                    Annuleer
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button type="submit" disabled={disabled}>
                    Opslaan
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default Edit;
