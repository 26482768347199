import {useCallback, useState} from 'react';
import KalmanFilter from 'kalmanjs';

const KALMAN_CONFIG = { R: 0.01, Q: 3 };

function kalman(list, key) {
  var kf = new KalmanFilter(KALMAN_CONFIG);
  return list.map(function (v) {
    return kf.filter(v[key]);
  }).slice(-1)[0];
}

function smoothPoint(points) {
  if (points.length === 1) return points[0];
  const lat = kalman(points, 'lat');
  const lng = kalman(points, 'lng');
  return { lat, lng };
}

// don't update waypoints during the process!!
function useWaypoints(smoothLength = 8) {
  // local state
  const [originalPoints, setOrigPoints] = useState([]);
  const [smoothedPoints, setPoints] = useState([]);

  const addPoint = useCallback((lat, lng) => setOrigPoints(currentPoints => {
    const newPoints = [...currentPoints, { lat, lng }];
    setPoints(p => ([...p, smoothPoint(newPoints.slice(-1 * smoothLength))]));
    return newPoints;
  }), [setPoints, setOrigPoints, smoothLength]);
  // return result and handler
  return [smoothedPoints, addPoint, originalPoints];
}

export default useWaypoints;