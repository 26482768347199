import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    fontSize: "14px",
    padding: theme.spacing(2)
  }
}));

const MyButton = props => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      fullWidth
      variant="contained"
      size="medium"
      color="primary"
      {...props}
    />
  );
};

export default MyButton;
