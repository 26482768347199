import { useEffect, useMemo } from "react";
import useSnackbar from "./useSnackbar";

export const useBrowserWarning = () => {
  const [snackbar] = useSnackbar();
  const browserWarning = useMemo(() => {
    // first try userAgent matching since we dont care about spoofing
    // eslint-disable-next-line no-useless-escape
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    // optimized for chrome and safari
    if (isSafari || iOS || isChrome) {
      return false;
    }
    // try some advanced browser detecting
    // Safari 3.0+ "[object HTMLElementConstructor]"
    const _isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof window.safari !== "undefined" &&
            window.safari.pushNotification)
      );
    // Chrome 1 - 71
    const _isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // optimized for chrome and safari
    return !_isChrome && !_isSafari;
  }, []);
  useEffect(() => {
    if (browserWarning) {
      snackbar(
        "Voor een optimale werking raden wij aan Google Chrome te gebruiken voor deze applicatie.",
        "warning"
      );
    }
  }, [snackbar, browserWarning]);
};
