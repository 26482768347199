import { useEffect } from "react";
import useSnackbar from "hooks/useSnackbar";

const GEOLOCATION_MESSAGE_UNSUPPORTED = "Uw huidige browser ondersteunt geen geolocatie. Maak gebruik van een andere meer moderne browser.";
const GEOLOCATION_MESSAGE_DENIED = "Het lijkt erop dat de locatietoegang momenteel uitgeschakeld is. Zet dit aan in uw browserinstellingen.";

const CheckGeolocation = () => {
  const [notification] = useSnackbar();

  useEffect(() => {
    // Check if the browser supports the Geolocation and Permissions APIs
    if (!navigator.geolocation || !navigator.permissions) {
      notification(
        GEOLOCATION_MESSAGE_UNSUPPORTED,
        "warning"
      );
      return;
    }

    // Check for geolocation permissions
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      const geoPermission = result.state; // 'granted', 'denied', or 'prompt'

      if (geoPermission === "granted" || geoPermission === "prompt") {
        // Trigger geolocation access
        navigator.geolocation.getCurrentPosition(
          // Success callback 
          () => null, 
          // Error callback
          (error) => {
            console.error("Error getting location:", error.message);
            if (error.code === error.PERMISSION_DENIED) {
              // Notify the user that they need to enable geolocation
              notification(
                GEOLOCATION_MESSAGE_DENIED,
                "warning"
              );
            }
        });
      }

      if (geoPermission === "denied") {
        // Notify the user that they need to enable geolocation
        notification(
          GEOLOCATION_MESSAGE_DENIED,
          "warning"
        );
      }

      // Listen for changes in permission state
      result.onchange = () => {
        if (result.state === "denied") {
          notification(
            GEOLOCATION_MESSAGE_DENIED,
            "warning"
          );
        }
      };
    });
  }, [notification]);

  return null;
};

export default CheckGeolocation;
