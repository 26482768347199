import React from "react";
import useState from "hooks/useState";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Snack = () => {
  const [
    {
      snackbar: { open, message, style }
    },
    dispatch
  ] = useState();

  const handleClose = () => dispatch({ type: "SNACKBAR_CLOSE" });

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      disableWindowBlurListener={true}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={style}>{message}</Alert>
    </Snackbar>
  );
};

export default Snack;
