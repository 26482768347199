import React, { useEffect, useState as localState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import useState from "hooks/useState";
import { usePosition } from "use-position";
import { baseUrl, mapKey, lineOptions, mapOptions } from "shared/constants";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import Header from "components/header";
import HeaderButton from "components/header-button";
import HeaderSuccessButton from "components/header-success-button";
import FabButton from "components/fab-button";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Situation from "pages/situation";
import CenterDot from "assets/center-dot-acc.svg";
import CenterDotManual from "assets/center-dot-manual.svg";
import Flag from "assets/flag.svg";
import useRoute from "hooks/useRoute";
import useWaypoints from "hooks/useWaypoints";
import useSnackbar from "hooks/useSnackbar";
import {useAPI} from "../shared/requests";
import Edit from "pages/edit";
import { makeStyles } from "@material-ui/core/styles";

function convertCoordinate(value, type) {
  if (value == null) {
    throw new Error(`${type} cannot be null or undefined`);
  }

  let num = typeof value === "string" ? parseFloat(value.replace(',', '.')) : Number(value);

  if (isNaN(num)) {
    throw new Error(`Invalid ${type}: ${value} cannot be converted to number`);
  }

  const ranges = {
    latitude: { min: -90, max: 90 },
    longitude: { min: -180, max: 180 }
  };

  if (!(type in ranges)) {
    throw new Error(`Invalid type: ${type}. Must be "latitude" or "longitude"`);
  }

  if (num < ranges[type].min || num > ranges[type].max) {
    throw new Error(`Invalid ${type}: ${num} must be between ${ranges[type].min} and ${ranges[type].max}`);
  }

  return Number(num.toFixed(7));
}

const convertLatLng = (lat, lng) => ({
  lat: convertCoordinate(lat, 'latitude'),
  lng: convertCoordinate(lng, 'longitude')
});

const getMarkerUrl = (isPositive, label) => {
  return `${baseUrl}/marker/${isPositive ? "green" : "red"}/${label}.svg`;
};

const useStyles = makeStyles({
  buttonPanel: {
    display: "flex",
    position: "fixed",
    bottom: "16px",
    left: "0",
    right: "0",
    width: "100%",
    justifyContent: "center",
    "& > *": {
      margin: "0 8px",
      maxWidth: "300px"
    }
  },
  defaultButton: {
    backgroundColor: "white"
  }
});

const Map = () => {
  const classes = useStyles();
  const route = useRoute();
  const history = useHistory();
  const api = useAPI();

  const watch = true;
  const { latitude, longitude, error } = usePosition(
    watch,
    {
      enableHighAccuracy: true
    }
  );

  const [startDialog, setStartDialog] = localState(false);
  const [waypoints, addWaypoint, origWaypoints] = useWaypoints();
  // eslint-disable-next-line no-unused-vars
  const [{ token }, dispatch] = useState();
  const [center, setCenter] = localState(convertLatLng(52.098017, 4.2705));
  const [situationDialog, setSituationDialog] = localState(false);
  const [disabled, setDisabled] = localState(false);
  const [completeDialog, setCompleteDialog] = localState(false);
  const [settingsDialog, setSettingsDialog] = localState(false);
  const [showNoGpsMap, setShowNoGpsMap] = localState(false);
  const [mapRef, setMapRef] = localState(null);

  const [open] = useSnackbar();

  const sendWaypoint = useCallback(
    (latitude, longitude) => {
      try {
        const coords = convertLatLng(latitude, longitude);
        
        dispatch({
          type: "DEBUG_ADD_LOG",
          payload: `sendWaypoint -> ${coords.lat} :: ${coords.lng}`
        });

        if (latitude === undefined || longitude === undefined) {
          return;
        }

        setCenter(coords);

        if (!!route && route.status.code === 0) {
          return;
        }

        addWaypoint(coords.lat, coords.lng);
        api.postWaypoint(route.id, coords.lat, coords.lng);
      } catch (error) {
        open(error.message, "error");
      }
    },
    [route, setCenter, addWaypoint, api, dispatch, open]
  );

  useEffect(() => {
    if (!latitude || !longitude) return;
    sendWaypoint(latitude, longitude);
  }, [latitude, longitude, sendWaypoint]);

  useEffect(() => {
    if (!!route && route.status.code === 0) {
      setStartDialog(true);
    }
    if (!!route && waypoints.length === 0) {
      route.waypoints.forEach(({ lat, lng }) => {
        try {
          const coords = convertLatLng(lat, lng);
          addWaypoint(coords.lat, coords.lng);
        } catch (error) {
          open(error.message, "error");
        }
      });
    }
  }, [route, setStartDialog, waypoints, addWaypoint, open]);

  const handleStartDialogClose = () => setStartDialog(false);
  const handleStartDialogProceed = () => {
    api.postRoute({ id: route.id, status: 1 })
      .finally(() => {
        dispatch({ type: "ROUTES_START", payload: route.id });
        setStartDialog(false);
        sendWaypoint(latitude, longitude);
      });
  };

  const handleAddSituation = () => {
    if (error === null) {
      setSituationDialog(true);
    } else {
      open(
        "GPS kan niet berekend worden. Schuif de map naar de gewenste locatie.",
        "warning"
      );
      setShowNoGpsMap(true);
    }
  };

  const handleNoGpsSituationCancel = () => setShowNoGpsMap(false);
  const handleNoGpsSituationAdd = () => {
    setSituationDialog(true);
    setShowNoGpsMap(false);
  };

  const handleCenterChange = () => {
    if (mapRef !== null && showNoGpsMap) {
      try {
        const coords = convertLatLng(
          mapRef.center.lat(),
          mapRef.center.lng()
        );
        setCenter(coords);
      } catch (error) {
        open(error.message, "error");
      }
    }
  };

  const handleSituationClose = () => setSituationDialog(false);

  const handlePause = () => {
    dispatch({
      type: "ROUTES_ADD_WAYPOINTS",
      payload: { id: !!route && route.id, waypoints: origWaypoints }
    });
    history.push("/");
  };

  const handleCompleteButton = () => setCompleteDialog(true);
  const handleCompleteCloseButton = () => setCompleteDialog(false);

  const handleCompleteRoute = () => {
    setDisabled(true);
    api.postRoute({ id: route.id, status: 9 })
      .then(() => {
        dispatch({ type: "ROUTES_COMPLETE", payload: route.id });
        dispatch({
          type: "ROUTES_ADD_WAYPOINTS",
          payload: { id: !!route ? route.id : -1, waypoints: origWaypoints }
        });
        history.push("/");
      })
      .finally(() => setDisabled(false));
  };

  const handleSettingsButton = () => setSettingsDialog(true);
  const handleSettingsClose = () => setSettingsDialog(false);

  const routeSituations = !!route && !!route.situations ? route.situations : [];

  return (
    <>
      <Header>
        {!!route && (
          <HeaderButton
            settingsIcon={true}
            disabled={disabled}
            onClick={handleSettingsButton}
          >
            Instellingen
          </HeaderButton>
        )}
        <HeaderButton
          settingsIcon={false}
          disabled={disabled}
          onClick={handlePause}
        >
          Pauzeren
        </HeaderButton>
        <HeaderSuccessButton disabled={disabled} onClick={handleCompleteButton}>
          Afronden
        </HeaderSuccessButton>
      </Header>
      <LoadScript googleMapsApiKey={mapKey} id="loadscriptid">
        <Polyline
          path={waypoints}
          options={{
            strokeColor: "#FF0000"
          }}
        />
        <GoogleMap
          id="googlemapid"
          zoom={18}
          center={center}
          options={mapOptions}
          onDragEnd={handleCenterChange}
          onLoad={setMapRef}
        >
          {!showNoGpsMap && (
            <>
              {!!waypoints && <Marker icon={Flag} position={waypoints[0]} />}
              <Polyline path={[...waypoints, center]} options={lineOptions} />
              {routeSituations.map(({ lat, lng, positive }, i) => {
                const position = convertLatLng(lat, lng);
                return (
                  <Marker
                    key={i}
                    position={position}
                    icon={getMarkerUrl(!!positive, i + 1)}
                  />
                );
              })}
              <Marker
                position={center}
                icon={{
                  url: CenterDot,
                  scaledSize: { width: 120, height: 120 },
                  anchor: { x: 60, y: 60 }
                }}
              />
            </>
          )}
          {showNoGpsMap && (
            <>
              <Marker
                position={center}
                icon={{
                  url: CenterDotManual,
                  scaledSize: { width: 120, height: 120 },
                  anchor: { x: 60, y: 60 }
                }}
              />
            </>
          )}
        </GoogleMap>
      </LoadScript>
      {!showNoGpsMap && (
        <FabButton onClick={handleAddSituation} disabled={disabled}>
          Plaats Melding
        </FabButton>
      )}
      {showNoGpsMap && (
        <div className={classes.buttonPanel}>
          <Button
            variant="default"
            className={classes.defaultButton}
            onClick={handleNoGpsSituationCancel}
          >
            Annuleren
          </Button>
          <Button onClick={handleNoGpsSituationAdd}>Handmatig toevoegen</Button>
        </div>
      )}
      <Dialog fullScreen open={situationDialog} onClose={handleSituationClose}>
        <Situation
          handleClose={handleSituationClose}
          route={route}
          longitude={center.lng}
          latitude={center.lat}
        />
      </Dialog>
      <Dialog fullScreen open={settingsDialog} onClose={handleSettingsClose}>
        <Edit handleClose={handleSettingsClose} route={route} />
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={startDialog}
        onClose={handleStartDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions>
          <Button onClick={handleStartDialogProceed} color="primary" autoFocus>
            Start Schouw
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={completeDialog}
        onClose={handleCompleteCloseButton}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Weet je zeker dat je wilt afronden?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            De schouw wordt afgerond. Je kunt deze terugvinden in de
            beheeromgeving.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCompleteCloseButton}
            color="primary"
            variant="text"
          >
            Annuleren
          </Button>
          <Button onClick={handleCompleteRoute} color="primary" autoFocus>
            Afronden
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Map;