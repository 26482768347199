export const baseUrl = process.env.REACT_APP_SERVER;
export const mapKey = process.env.REACT_APP_GOOGLE_MAP;
export const geolocationUrl = "https://maps.googleapis.com/maps/api/geocode";
export const isProduction = process.env.NODE_ENV === "production";
export const localStorageId = "ccv";
export const cookieName = "ccv-api-key";
export const lineOptions = {
  strokeColor: "#33ADD6"
};
export const mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  styles: [
    {
      "featureType": "poi.business",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    }
  ]
};
