import { useParams } from "react-router-dom";
import useState from "hooks/useState";

const useRoute = () => {
  const { id } = useParams();
  const [{ routes }] = useState();

  if (!!routes.value) {
    for (const r of routes.value) {
      if (r.id === Number(id)) {
        return r;
      }
    }
  }

  return null;
};

export default useRoute;
