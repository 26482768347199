import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "800px",
    marginLeft: "auto",
    marginRight: "auto",
    boxSizing: "border-box"
  }
});

const Header = ({ children }) => {
  const classes = useStyles();
  return (
    <AppBar position="sticky">
      <Toolbar className={classes.root}>{children}</Toolbar>
    </AppBar>
  );
};

export default Header;
