import React, { useEffect, useState as localState } from "react";
import {
  setInit,
  setLoading,
  NOT_ASKED,
  SUCCESS
} from "shared/remote-data";
import { mapKey, geolocationUrl } from "shared/constants";
import { useHistory } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import useState from "hooks/useState";
import { useFormValue, useFormElement, isEmpty } from "hooks/useForm";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Button from "components/button";
import Box from "@material-ui/core/Box";
import Header from "components/header";
import BackButton from "components/back-button";
import TextTitle from "components/text-title";
import SubTitle from "components/sub-title";
import TextField from "components/textfield";
import LogoutButton from "components/logout-button";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Alert from "@material-ui/lab/Alert";
import StepLabel from "@material-ui/core/StepLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useAPI} from "../shared/requests";
import { usePosition } from "use-position";

const getSteps = () => {
  return ["Gegevens", "Gebied", "Partijen"];
};

const New = ({ id = null }) => {
  const history = useHistory();
  const steps = getSteps();
  const [{ groups, token }, dispatch] = useState();
  const [attendees, setAttendees] = localState([]);
  const [categoryError, setCategoryError] = localState("");
  const [locationLoaded, setLocationLoaded] = localState(false);
  const [locationLoadedError, setLocationLoadedError] = localState(false);
  const api = useAPI();
  const watch = true;
  const { latitude, longitude, error } = usePosition(watch, {
    enableHighAccuracy: true
  });

  useEffect(() => {
    setLocationLoadedError(error !== null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!locationLoaded && latitude !== undefined && longitude !== undefined) {
      setLocationLoaded(true);
      fetch(
        `${geolocationUrl}/json?latlng=${latitude},${longitude}&key=${mapKey}&language=nl`,
        {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer"
        }
      )
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then(({ status, results }) => {
          if (status === "OK") {
            results.forEach(result => {
              if (!!result.address_components) {
                result.address_components.forEach(({ long_name, types }) => {
                  if (!!types && !!long_name) {
                    if (types.includes("postal_code")) {
                      dispatch({
                        type: "FORM_DATA_UPDATE_VALUE",
                        payload: {
                          id: "form-new-postal-code",
                          field: "value",
                          value: long_name
                        }
                      });
                    } else if (types.includes("locality")) {
                      dispatch({
                        type: "FORM_DATA_UPDATE_VALUE",
                        payload: {
                          id: "form-new-city",
                          field: "value",
                          value: long_name
                        }
                      });
                      // long_name is city
                    }
                  }
                });
              }
            });
          }
        })
        .catch(err => {
          alert(`err :: ${err}`);
        });
    }
  }, [latitude, longitude, locationLoaded, setLocationLoaded]);

  // get groups

  useEffect(() => {
    if (groups.state === NOT_ASKED) {
      dispatch({ type: "GROUPS_SET", payload: setLoading() });
      api.getGroups();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups.state, history, token]);

  // form values

  const [disabled, setDisabled] = useFormValue("form-new-disabled", false);

  const [step, setStep] = useFormValue("form-new-step", 0);

  const [name, validateName] = useFormElement("form-new-title", "Titel", "", [
    { validation: isEmpty, message: "Titel mag niet leeg zijn" }
  ]);

  const [description] = useFormElement(
    "form-new-description",
    "Omschrijving",
    "",
    []
  );

  const [postalCode, validatePostalCode] = useFormElement(
    "form-new-postal-code",
    "Postcode",
    "",
    [{ validation: isEmpty, message: "Postcode mag niet leeg zijn" }]
  );

  const [city, validateCity] = useFormElement("form-new-city", "Plaats", "", [
    { validation: isEmpty, message: "Plaats mag niet leeg zijn" }
  ]);

  const [groupId, setGroupId] = useFormValue("form-new-group-id", -1);

  const resetFields = () => {
    dispatch({ type: "FORM_RESET_BATCH", payload: "form-new-" });
  };

  // form validations

  const handleSubmitGegevens = event => {
    event.preventDefault();

    const validationResult = [
      validateName(),
      validatePostalCode(),
      validateCity()
    ].filter(i => i !== null);

    if (validationResult.length === 0) {
      setStep(1);
    }
  };

  const handleSubmitGroup = event => {
    event.preventDefault();

    if (groupId < 0) {
      setCategoryError("Een keuze is verplicht");
    } else {
      setStep(2);
    }
  };

  const handleSubmitAanwezigen = event => {
    event.preventDefault();

    if (groupId < 0) {
      setCategoryError("Een keuze is verplicht");
    } else {
      setCategoryError("");
      setDisabled(true);

      const result = {
        name: name.value,
        description: description.value,
        postal_code: postalCode.value,
        city: city.value,
        category_id: groupId,
        attendees: attendees
          .filter(({ selected }) => selected)
          .map(({ id, names }) => ({ group_id: id, name: names }))
      };

      api.postRoute(result)
        .then(({ route_id }) => {
          dispatch({
            type: "ROUTES_ADD",
            payload: {
              ...result,
              id: route_id,
              category: { id: groupId },
              situations: [],
              waypoints: [],
              status: {
                code: 0,
                name: "Niet gestart"
              }
            }
          });
          history.replace(`/map/${route_id}`, { from: "new", id: route_id });
        })
        .catch(err => {
          history.push("/login");
          dispatch({ type: "GROUPS_SET", payload: setInit() });
        })
        .finally(() => {
          setDisabled(false);
          resetFields();
        });
    }
  };

  // events

  const handleStepBack = () => setStep(0);
  const handleStepMiddle = () => setStep(1);

  const handleGroupSelect = ({ target: { value } }) => {
    setCategoryError("");
    setGroupId(value);

    const result = groups.value.filter(({ id }) => {
      return id === Number(value);
    });

    setAttendees(
      result.length === 0
        ? []
        : result[0].groups.map(g => ({ ...g, selected: false, names: "" }))
    );
  };

  const handleAttendeeClick = id => {
    setAttendees(old => {
      return old.map(a => {
        if (a.id === id) {
          return { ...a, selected: !a.selected };
        }
        return a;
      });
    });
  };

  const handleAttendeeName = (id, value) => {
    setAttendees(old => {
      return old.map(a => {
        if (a.id === id) {
          return { ...a, names: value };
        }
        return a;
      });
    });
  };

  const handleBackButton = () => {
    resetFields();
    history.push("/");
  };

  return (
    <>
      <Header>
        <BackButton onClick={handleBackButton}>Terug naar overzicht</BackButton>
        <LogoutButton />
      </Header>
      <Container maxWidth="sm" disableGutters>
        <Box ml={2} mr={2}>
          <Box mt={4} mb={3}>
            <TextTitle>Nieuwe schouw aanmaken</TextTitle>
          </Box>
          <Box mt={4} mb={3}>
            <Paper square={true}>
              <Stepper activeStep={step}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Paper>
          </Box>
        </Box>
        {step === 0 && (
          <Box ml={2} mr={2}>
            <form noValidate autoComplete="off" onSubmit={handleSubmitGegevens}>
              <TextField
                placeholder="Vul hier de titel in"
                type="text"
                required
                margin="normal"
                {...name}
                disabled={disabled}
              />
              <TextField
                placeholder="Vul hier de omschrijving in"
                type="text"
                multiline
                margin="normal"
                rows={3}
                {...description}
                disabled={disabled}
              />
              {locationLoadedError && (
                <Alert severity="warning">
                  De locatie kan niet worden bepaald. Vul je postcode en plaats
                  handmatig in
                </Alert>
              )}
              <TextField
                placeholder="Vul hier de postcode in"
                type="text"
                required
                margin="normal"
                {...postalCode}
                disabled={disabled}
              />
              <TextField
                placeholder="Vul hier de plaats in"
                type="text"
                required
                margin="normal"
                {...city}
                disabled={disabled}
              />
              <Box mt={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} />
                  <Grid item xs={6} sm={6}>
                    <Button type="submit" disabled={disabled}>
                      Ga verder
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        )}
        {step === 1 && (
          <form noValidate autoComplete="off" onSubmit={handleSubmitGroup}>
            <Box ml={2} mr={2} mb={2}>
              <SubTitle>Kies een gebied*</SubTitle>
              <FormControl
                variant="filled"
                fullWidth
                error={categoryError !== ""}
              >
                <InputLabel htmlFor="form-route-settings-category">
                  {categoryError}
                </InputLabel>
                <Select
                  native
                  required
                  value={groupId}
                  disabled={disabled || groups.state !== SUCCESS}
                  onChange={handleGroupSelect}
                >
                  <option value={-1}>Kies een gebied</option>
                  {groups.state === SUCCESS &&
                    groups.value.map(({ id, title }) => (
                      <option key={id} value={id}>
                        {title}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={4} ml={2} mr={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Button
                    disabled={disabled}
                    variant="text"
                    onClick={handleStepBack}
                  >
                    Ga terug
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button type="submit" disabled={disabled}>
                    Naar partijen
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
        {step === 2 && (
          <form noValidate autoComplete="off" onSubmit={handleSubmitAanwezigen}>
            {attendees.length > 0 && (
              <List>
                {attendees.map(({ id, name, selected, names }) => (
                  <div style={{ position: "relative" }} key={id}>
                    <ListItem
                      button
                      disabled={disabled}
                      onClick={() => handleAttendeeClick(id)}
                    >
                      <ListItemIcon>
                        <>
                          {!selected && <CheckBoxOutlineBlankIcon />}
                          {selected && <CheckBoxIcon />}
                        </>
                      </ListItemIcon>
                      <ListItemText>{name}</ListItemText>
                    </ListItem>
                    <Collapse in={selected}>
                      <Box ml={2} mr={2} mt={1}>
                        <TextField
                          label="Namen (optioneel)"
                          placeholder="Vul hier alle namen in"
                          value={names}
                          disabled={disabled}
                          onChange={({ target: { value } }) =>
                            handleAttendeeName(id, value)
                          }
                        />
                      </Box>
                    </Collapse>
                  </div>
                ))}
              </List>
            )}
            <Box mt={4} ml={2} mr={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <Button
                    disabled={disabled}
                    variant="text"
                    onClick={handleStepMiddle}
                  >
                    Ga terug
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button type="submit" disabled={disabled}>
                    Naar de kaart
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Container>
    </>
  );
};

export default New;
