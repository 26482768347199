import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#33A54C",
    color: "white",
    "&:hover": {
      backgroundColor: "#33A54C"
    }
  }
});

const HeaderButton = props => {
  const classes = useStyles();
  return <Button className={classes.button} variant="contained" {...props} />;
};

export default HeaderButton;
