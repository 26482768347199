import Cookies from "js-cookie";
import { cookieName, isProduction } from "shared/constants";
import { setInit, setValue, SUCCESS } from "shared/remote-data";

// module vars :-(
let updatedFormData, id, updatedRoutes;

export const initialState = {
  online: true,
  splash: true,
  token: isProduction && Cookies.get(cookieName),
  routes: setInit(),
  formData: {},
  snackbar: {
    open: false,
    message: "",
    style: "success"
  },
  groups: setInit(),
  categories: setInit(),
  debugConsole: ["** console **"]
};

export const reducer = (state, { type, payload }) => {
  state = { ...state, debugConsole: [...state.debugConsole, type] };
  switch (type) {
    case "GROUPS_SET":
      return { ...state, groups: payload };
    case "ONLINE_SET":
      return { ...state, online: payload };
    case "SPLASH_SET":
      return { ...state, splash: payload };
    case "TOKEN_SET":
      Cookies.set(cookieName, payload);
      return { ...state, token: payload };
    case "SNACKBAR_OPEN":
      return {
        ...state,
        snackbar: {
          open: true,
          message: payload.message,
          style: payload.style
        }
      };
    // Logout
    case "LOGOUT":
      isProduction && Cookies.remove(cookieName);
      return { ...initialState, splash: false };
    case "SNACKBAR_CLOSE":
      return { ...state, snackbar: { ...state.snackbar, open: false } };
    case "CATEGORIES_SET":
      if (payload.state === SUCCESS) {
        orderCategories(payload.value);
      }
      return { ...state, categories: payload };
    case "ROUTES_SET":
      return { ...state, routes: payload };
    case "ROUTES_ADD_WAYPOINTS":
      if (state.routes.state === SUCCESS) {
        updatedRoutes = state.routes.value.map(r => {
          if (r.id === payload.id) {
            r.waypoints = payload.waypoints;
          }
          return r;
        });
        return {
          ...state,
          routes: setValue(updatedRoutes)
        };
      }
      return state;
    case "ROUTES_ADD_SITUATION":
      if (state.routes.state === SUCCESS) {
        updatedRoutes = state.routes.value.map(r => {
          if (r.id === parseInt(payload.id)) {
            r.situations = [...r.situations, payload.situation];
          }
          return r;
        });
        return {
          ...state,
          routes: setValue(updatedRoutes)
        };
      }
      return state;
    case "ROUTES_START":
      if (state.routes.state === SUCCESS) {
        updatedRoutes = state.routes.value.map(r => {
          if (r.id === payload) {
            r.status = { code: 1, name: "Lopend" };
          }
          return r;
        });
        return {
          ...state,
          routes: setValue(updatedRoutes)
        };
      }
      return state;
    case "ROUTES_ADD":
      if (state.routes.state === SUCCESS) {
        updatedRoutes = [
          ...state.routes.value,
          { ...payload, status: { code: 0, name: "Niet gestart" } }
        ];
        return { ...state, routes: setValue(updatedRoutes) };
      }
      return state;
    case "ROUTES_UPDATE":
      if (state.routes.state === SUCCESS) {
        updatedRoutes = state.routes.value.map(r => {
          if (r.id === payload.id) {
            return payload;
          }
          return r;
        });
        return { ...state, routes: setValue(updatedRoutes) };
      }
      return state;
    case "ROUTES_COMPLETE":
      if (state.routes.state === SUCCESS) {
        updatedRoutes = state.routes.value.map(r => {
          if (r.id === payload) {
            return { ...r, status: { code: 9, name: "Afgerond" } };
          } else {
            return r;
          }
        });
        return { ...state, routes: setValue(updatedRoutes) };
      }
      return state;
    // Form data
    case "FORM_DATA_SET":
      updatedFormData = { ...state.formData };
      updatedFormData[payload.id] = payload.value;
      return { ...state, formData: updatedFormData };
    case "FORM_DATA_UPDATE_VALUE":
      updatedFormData = { ...state.formData };
      updatedFormData[payload.id][payload.field] = payload.value;
      return { ...state, formData: updatedFormData };
    case "FORM_RESET_FIELDS":
      updatedFormData = { ...state.formData };
      for (let i = 0; i < payload.length; i++) {
        id = payload[i];
        if (updatedFormData[id] !== undefined) {
          updatedFormData[id].value = updatedFormData[id].initialValue;
          updatedFormData[id].error = null;
        }
      }
      return { ...state, formData: updatedFormData };
    case "FORM_RESET_BATCH":
      updatedFormData = { ...state.formData };
      for (let key in updatedFormData) {
        if (key.startsWith(payload)) {
          updatedFormData[key].value = updatedFormData[key].initialValue;
          updatedFormData[key].error = null;
        }
      }
      return { ...state, formData: updatedFormData };
    case "DEBUG_ADD_LOG":
      return state;
    case "DEBUG_LOG_STATE":
      console.log("state", state);
      return state;
    default:
      return state;
  }
};

function orderCategories(categories) {
  let result = {};
  for (let [key, value] of Object.entries(categories)) {
    result[key] = value.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
  return result;
}
