import React, { Fragment } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import theme from "shared/material-theme";
import Bootstrap from "shared/bootstrap";
import Splash from "pages/splash";
import OverviewPage from "pages/overview";
import LoginPage from "pages/login";
import NewPage from "pages/new";
import MapPage from "pages/map";
import PasswordPage from "pages/password";
import Snackbar from "components/snackbar";
import DataWrap from "shared/data-wrap";
import CheckGeolocation from "shared/check-geolocation";
import { useBrowserWarning } from "./hooks/useBrowserWarning";

const App = () => {
  useBrowserWarning();
  return (
    <Fragment>
      <Router basename={process.env.REACT_APP_ROUTER_PREFIX || ""}>
        <Splash />
        <Switch>
          {process.env.NODE_ENV === "production" ? (
            <Route
              exact
              path="/login"
              render={() => {
                window.location.href = process.env.REACT_APP_LOGIN_URL;
                return null;
              }}
            />
          ) : (
            <Route exact path="/login">
              <LoginPage />
            </Route>
          )}
          <DataWrap>
            <Route exact path="/password">
              <PasswordPage />
            </Route>
            <Route exact path="/new">
              <NewPage />
            </Route>
            <Route exact path="/map/:id">
              <MapPage />
            </Route>
            <Route exact path="/">
              <OverviewPage />
            </Route>
          </DataWrap>
        </Switch>
      </Router>
      <CheckGeolocation />
      <Snackbar />
    </Fragment>
  );
};

export default () => (
  <ThemeProvider theme={theme}>
    <Bootstrap>
      <App />
    </Bootstrap>
  </ThemeProvider>
);
