import { useCallback } from "react";
import useState from "hooks/useState";

const useSnackbar = () => {
  const [, dispatch] = useState();

  const open = useCallback(
    (message, style) =>
      dispatch({ type: "SNACKBAR_OPEN", payload: { message, style } }),
    []
  );

  const close = () => dispatch({ type: "SNACKBAR_CLOSE" });

  return [open, close];
};

export default useSnackbar;
