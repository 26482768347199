import React from "react";
import TextField from "@material-ui/core/TextField";

const Input = props => (
  <TextField
    fullWidth
    variant="filled"
    InputLabelProps={{
      shrink: true
    }}
    {...props}
    inputProps={{ "data-lpignore": true }}
  />
);

export default Input;
