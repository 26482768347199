import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles({
  button: {
    color: "white",
    fontSize: "9px"
  }
});

const HeaderButton = ({ settingsIcon = true, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      startIcon={settingsIcon ? <SettingsIcon /> : <PauseCircleOutlineIcon />}
      {...props}
    />
  );
};

export default HeaderButton;
