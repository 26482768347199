import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  headerButton: {
    color: "white",
    textTransform: "none"
  }
});

const BackButton = props => {
  const classes = useStyles();
  return (
    <Button
      className={classes.headerButton}
      startIcon={<ArrowLeftIcon />}
      {...props}
    />
  );
};

export default BackButton;
