import React, { createContext, useReducer, useEffect } from "react";
import { reducer, initialState } from "shared/reducer";
import { doFetch } from "./requests";
import { baseUrl } from "./constants";

export const Context = createContext(initialState);

const Bootstrap = ({ children }) => {
  // global state
  const [state, dispatch] = useReducer(reducer, initialState);

  // online checker
  useEffect(() => {
    let timer = null;

    async function loadPingPong() {
      await doFetch(`${baseUrl}/ping`).then(response => response.json())
        .then(() => {
          if (!state.online) dispatch({ type: "ONLINE_SET", payload: true });
        })
        .catch(() => {
          if (state.online) dispatch({ type: "ONLINE_SET", payload: false });
        })
        .finally(() => {
          timer = window.setTimeout(loadPingPong, 2000);
        });
    }

    loadPingPong();

    return () => {
      if (timer !== null) {
        window.clearTimeout(timer);
      }
    };
  }, [state.online]);

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export default Bootstrap;
