import React from "react";
import useState from "hooks/useState";
import useMount from "hooks/useMount";
import { useHistory } from "react-router-dom";
import SplashComponent from "components/splash";
import { setValue, setLoading, NOT_ASKED, setError } from "shared/remote-data";
import {useAPI} from "../shared/requests";

const Splash = () => {
  const [{ splash, routes }, dispatch] = useState();
  const history = useHistory();
  const api = useAPI();

  useMount(() => {
    if (routes.state === NOT_ASKED) {
      dispatch({ type: "ROUTES_SET", payload: setLoading() });
      api.getRoutes()
        .then(result => {
          dispatch({ type: "ROUTES_SET", payload: setValue(result) });
        })
        .catch(err => {
          dispatch({ type: "ROUTES_SET", payload: setError(err) });
          history.replace("/login");
        })
        .finally(() => dispatch({ type: "SPLASH_SET", payload: false }));
      // loadData(token, dispatch, history);
    }
  });

  return <SplashComponent active={splash} />;
};

export default Splash;
