import React, {
  useState as localState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SubTitle from "components/sub-title";
import Typography from "@material-ui/core/Typography";
import Button from "components/button";
import useState from "hooks/useState";
import { useFormElement, useFormValue } from "hooks/useForm";
import Header from "components/header";
import { mapKey, geolocationUrl } from "shared/constants";
import {
  setLoading,
  NOT_ASKED,
  SUCCESS,
} from "shared/remote-data";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "components/textfield";
import { makeStyles } from "@material-ui/core/styles";
import { previewImage } from "shared/image-scaler";
import { useAPI } from "../shared/requests";
import Img from "react-fix-image-orientation";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: `14px`,
    padding: theme.spacing(2),
  },
  negativeButton: {
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    "&:hover": {
      backgroundColor: "rgba(208, 32, 31, .4)",
      color: "#D0201F",
    },
  },
  negativeButtonActive: {
    backgroundColor: "rgba(208, 32, 31, .24)",
    color: "#D0201F",
  },
  positiveButton: {
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    "&:hover": {
      backgroundColor: "rgba(51, 165, 76, .4)",
      color: "#33A54C",
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    margin: "16px",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  positiveButtonActive: {
    backgroundColor: "rgba(51, 165, 76, .24)",
    color: "#33A54C",
  },
  label: {
    fontWeight: "bold",
  },
  imageBox: {
    backgroundColor: "#eeeeee",
    position: "relative",
    display: "block",
    width: "100%",
    minHeight: "200px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  imageBoxInner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  imageBoxInnerDelete: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  input: {
    display: "none",
  },
}));

function parseAddressEntry({ address_components }) {
  let result = { size: 0 };
  for (let i = 0; i < address_components.length; i++) {
    const { long_name = "", types = [] } = address_components[i];
    if (types.includes("street_number") && !!long_name) {
      result.house_nr = long_name;
      result.size++;
    } else if (types.includes("route") && !!long_name) {
      result.street = long_name;
      result.size++;
    } else if (types.includes("postal_code") && !!long_name) {
      result.zipcode = long_name;
      result.size++;
    } else if (types.includes("locality") && !!long_name) {
      result.city = long_name;
      result.size++;
    }
  }
  return result;
}

const Situation = ({ handleClose, route, longitude, latitude }) => {
  const classes = useStyles();
  const history = useHistory();
  const api = useAPI();
  const uploadButton = useRef();

  const [{ categories, groups, token }, dispatch] = useState();
  const [dialog, setDialog] = localState(false);
  // eslint-disable-next-line no-unused-vars
  const [categoryError, setCategoryError] = localState("");
  const [groupTypeError, setGroupTypeError] = localState("");
  const [types, setTypes] = localState([]);

  const [locationInfo, setLocationInfo] = localState({});

  const setCategories = useCallback(
    (iid = route.category.id) => {
      if (categories.state === SUCCESS && groups.state === SUCCESS) {
        const g = groups.value
          .filter(({ id }) => id === Number(iid))
          .map(({ title }) => title);
        if (g.length > 0) {
          setTypes(categories.value[g[0]]);
        } else {
          setTypes([]);
        }
      }
    },
    [
      categories.state,
      groups.state,
      categories.value,
      groups.value,
      route.category.id,
      setTypes,
    ]
  );

  // get groups
  useEffect(() => {
    if (groups.state === NOT_ASKED) {
      dispatch({ type: "GROUPS_SET", payload: setLoading() });
      api.getGroups().finally(setCategories);
    } else {
      setCategories();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCategories, groups.state, history, token]);

  // get location info
  useEffect(() => {
    fetch(
      `${geolocationUrl}/json?latlng=${latitude},${longitude}&key=${mapKey}&language=nl`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(({ status, results }) => {
        if (status === "OK") {
          let result = results
            .filter(
              (r) =>
                !!r.address_components && r.types.includes("street_address")
            )
            .map(parseAddressEntry)
            .sort((a, b) => {
              let comparison = 0;
              if (a.size > b.size) {
                comparison = -1;
              } else {
                comparison = 1;
              }
              return comparison;
            });

          if (result.length > 0) {
            setLocationInfo(result[0]);
          }
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude]);

  // get categories

  useEffect(() => {
    if (categories.state === NOT_ASKED) {
      dispatch({ type: "CATEGORIES_SET", payload: setLoading() });
      api.getCategories().finally(setCategories);
    } else {
      setCategories();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCategories, categories.state, history, token]);

  // form values

  const [disabled, setDisabled] = useFormValue("form-situ-disabled", false);
  const [isNegative, setIsNegative] = useFormValue(
    "form-situ-is-negative",
    true
  );
  const [category, setCategory] = useFormValue(
    "form-situ-category",
    route.category.id || -1
  );
  const [groupType, setGroupType] = useFormValue("form-situ-group-type", -1);
  const [description] = useFormElement(
    "form-situ-description",
    "Omschrijving",
    "",
    []
  );
  const [image, setImage] = useFormValue("form-situ-image", null);

  const resetFields = () =>
    dispatch({ type: "FORM_RESET_BATCH", payload: "form-situ-" });

  // form submit

  const submitSituation = (event) => {
    event.preventDefault();

    if (category < 0 || groupType < 0) {
      if (category < 0) {
        setCategoryError("Gebiedskeuze is verplicht");
      }
      if (groupType < 0) {
        setGroupTypeError("Type is verplicht");
      }
    } else {
      setCategoryError("");
      setGroupTypeError("");
      setDisabled(true);

      const result = {
        ...locationInfo,
        subcategory_id: groupType,
        description: description.value,
        image: image === null ? "" : image.split(",")[1],
        positive: isNegative ? 0 : 1,
        lat: latitude,
        lng: longitude,
        latitude,
        longitude,
      };

      api
        .postSituation(route.id, result)
        .then((res) => {
          if (res) {
            dispatch({
              type: "ROUTES_ADD_SITUATION",
              payload: { id: route.id, situation: result },
            });
          }
        })
        .finally(() => {
          resetFields();
          handleClose();
        });
    }
  };

  // events

  const handleDialogClose = () => {
    resetFields();
    handleClose();
  };
  const handleDialogDeleteAndClose = () => {
    setImage(null);
    setDialog(false);
  };
  // eslint-disable-next-line no-unused-vars
  const handleCategorySelect = ({ target: { value } }) => {
    setCategory(value);
    setCategories(value);
  };
  const handleGroupTypeSelect = ({ target: { value } }) => {
    setGroupType(Number(value));
  };
  const handleCloseClick = handleDialogClose;

  const handleUploadButtonClick = (event) => {
    !!uploadButton.current && uploadButton.current.click();
  };

  const handleUploadClick = (event) => {
    let file = event.target.files[0];
    previewImage(file, setImage);
  };

  const handleDeletePhotoClick = () => {
    setDialog(true);
  };

  return (
    <>
      <Header>
        <Typography variant="subtitle2">Plaats melding</Typography>
      </Header>
      <Container maxWidth="sm">
        <form noValidate autoComplete="off" onSubmit={submitSituation}>
          <Box mt={4}>
            <SubTitle>Type*</SubTitle>
            <FormControl
              variant="filled"
              fullWidth
              error={groupTypeError !== ""}
            >
              <InputLabel htmlFor="form-route-settings-category">
                {groupTypeError}
              </InputLabel>
              <Select
                native
                required
                value={groupType}
                disabled={disabled || categories.state !== SUCCESS}
                onChange={handleGroupTypeSelect}
              >
                <option value={-1}>Kies een type</option>
                {!!types &&
                  types.map(({ name, id }) => (
                    <option key={id} value={Number(id)}>
                      {name}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2} mb={2}>
            <Grid container spacing={0}>
              <Grid item xs={6} sm={6}>
                <Button
                  className={`${classes.button} ${classes.negativeButton} ${
                    isNegative ? classes.negativeButtonActive : ""
                  }`}
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="default"
                  disableElevation
                  onClick={() => setIsNegative(true)}
                  startIcon={<ThumbDownAltIcon />}
                  disabled={disabled}
                >
                  Negatief
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  className={`${classes.button} ${classes.positiveButton} ${
                    !isNegative ? classes.positiveButtonActive : ""
                  }`}
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="default"
                  disableElevation
                  onClick={() => setIsNegative(false)}
                  startIcon={<ThumbUpAltIcon />}
                  disabled={disabled}
                >
                  Positief
                </Button>
              </Grid>
            </Grid>
          </Box>
          <TextField
            placeholder="Vul hier de omschrijving in"
            type="text"
            fullWidth
            variant="filled"
            InputLabelProps={{ shrink: true }}
            multiline
            margin="normal"
            rows={3}
            {...description}
            disabled={disabled}
          />
          <Box mt={2}>
            <div className={classes.imageBox}>
              {image !== null && (
                <Img
                  alt="situation visualisation"
                  src={image}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              )}
              {image === null && (
                <div className={classes.imageBoxInner}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                    ref={uploadButton}
                  />
                  <label htmlFor="contained-button-file--">
                    <Button
                      className={classes.button}
                      variant="contained"
                      size="medium"
                      color="primary"
                      startIcon={<CameraAltIcon />}
                      disabled={disabled}
                      onClick={handleUploadButtonClick}
                    >
                      Maak Foto
                    </Button>
                  </label>
                </div>
              )}
              {image !== null && (
                <div
                  className={`${classes.imageBoxInner} ${classes.imageBoxInnerDelete}`}
                >
                  <Button
                    className={`${classes.button} ${classes.deleteButton}`}
                    variant="contained"
                    size="medium"
                    startIcon={<DeleteIcon />}
                    disabled={disabled}
                    onClick={handleDeletePhotoClick}
                  >
                    Verwijder Foto
                  </Button>
                </div>
              )}
            </div>
          </Box>
          <Box mt={4}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <Button
                  className={classes.button}
                  fullWidth
                  variant="outlined"
                  size="medium"
                  color="default"
                  onClick={handleCloseClick}
                >
                  Annuleren
                </Button>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  type="submit"
                  className={classes.button}
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  Opslaan
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
      <Dialog
        open={dialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Weet je zeker dat je de foto wilt verwijderen?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialog(false)} color="primary">
            Annuleren
          </Button>
          <Button
            onClick={handleDialogDeleteAndClose}
            color="primary"
            autoFocus
          >
            Verwijderen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Situation;
