import {baseUrl, isProduction} from './constants';
import useState from "../hooks/useState";
import {setInit, setValue} from "./remote-data";
import {useHistory, useParams} from "react-router-dom";
import useSnackbar from "../hooks/useSnackbar";
import useInterval from "../hooks/useInterval";

// storage checker
function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch(e) {
    return e instanceof DOMException && (
        // everything except Firefox
        e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        (storage && storage.length !== 0);
  }
}

// centralized place for adding fetch options, login/logout redirects
export const doFetch = (url, options={}) =>
    fetch(url, { mode: isProduction ? 'same-origin' : 'cors', ...options });

// module context
let self = {}, queue = [], isRunning = false;

// define all api calls here to keep it in a central place
export function useAPI(settings = {}) {
  settings = {
    debug: false,
    retryLimit: 480, // try for 4 hrs or more
    retryTimeout: 60000, // delay 1 minute between tries
    useLocalStorage: true,
    localStorageKey: 'CCVPersistentQueue',
    ...settings,
  };
  const params = useParams();
  const hasLocalStorage = storageAvailable('localStorage');
  const [{ token }, dispatch] = useState();
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [dialog, _] = useSnackbar();

  useInterval(() => tick(), settings.retryTimeout);

  function renewKey() {
    return doFetch(`${baseUrl}/renew/${token}`)
        .then(resp => {
          if (!resp.ok) {
            throw resp;
          }
          return resp.json();
        })
        .then(resp => {
          dispatch({ type: "TOKEN_SET", payload: resp.apiToken });
          return resp;
        })
        .catch(() => gotoLogin());
  }
  function _fetch(url, key=false, opts={}) {
    return doFetch(`${url}?apiKey=${key}`, opts).then(function (resp) {
      if (!resp.ok) {
        if (resp.status === 403) {
          // renew key and re-fire request
          return renewKey().then(resp => {
            return doFetch(`${url}?apiKey=${resp.apiToken}`, opts)
              .then(resp => {
                if (!resp.ok) {
                  throw resp;
                }
                return resp.json();
              });
          });
        }
        throw resp;
      }
      return resp.json();
    });
  }

  // get routes
  self.getRoutes = () => _fetch(`${baseUrl}/routes`, token);

  // get groups
  self.getGroups = () => _fetch(`${baseUrl}/groups`, token)
      .then(result => {
        dispatch({ type: "GROUPS_SET", payload: setValue(result) });
      })
      .catch(err => {
        history.push("/login");
        dispatch({ type: "GROUPS_SET", payload: setInit() });
      });

  // get categories
  self.getCategories = () => _fetch(`${baseUrl}/categories`, token)
      .then(result => {
        dispatch({ type: "CATEGORIES_SET", payload: setValue(result) });
      })
      .catch(err => {
        history.push("/login");
        dispatch({ type: "CATEGORIES_SET", payload: setInit() });
      })

  // submit a new waypoint
  self.postWaypoint = (route_id, latitude, longitude) => _fetch(`${baseUrl}/waypoint/${route_id}`, token,{
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ latitude, longitude })
  });

  // edit route data
  self.postRoute = data => _fetch(`${baseUrl}/route`, token, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  });

  // post password-reset
  self.postPasswordReset = email => _fetch(`${baseUrl}/password-reset`, token, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email })
  });

  // authenticate
  self.authenticate = (email, password) => doFetch(`${baseUrl}/authenticate`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password })
  }).then(result => result.json());

  // with queue looper
  self.postSituation = (route_id, data) => _fetch(`${baseUrl}/situation/${route_id}`, token, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
      .catch(() => {
        dialog("Er is iets fout gegaan bij het versturen. We proberen de melding nog een aantal keer aan te bieden.", "warning");
        add(`${baseUrl}/situation/${route_id}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        }, 1);
      });

  // migrated from FetchQueue
  function populateQueue() {
    let result = JSON.parse(localStorage.getItem(settings.localStorageKey) || '{"size":0}');
    if (!!result.size && !!result.items) {
      queue = result.items.slice();
      if ( ! isRunning) {
        tick();
      }
    }
  }
  function serializeQueue() {
    const q = queue.slice();
    localStorage.setItem(settings.localStorageKey, JSON.stringify({
      size: q.length,
      items: q,
    }));
  }

  // Add a request back into the queue
  function add(url, options, counter) {
    queue.push([url, options, counter]);
    if (settings.useLocalStorage && hasLocalStorage) {
      serializeQueue();
    }
  }

  // Recursive queue runner
  function tick() {
    if (isRunning || !params || !params.id) return;
    if (queue.length === 0) {
      isRunning = false;
      return;
    }
    isRunning = true;
    const requests = queue.slice().reverse();
    const route_id = params.id;
    localStorage.setItem(settings.localStorageKey, '{"size":0}');
    queue = [];

    Promise.all(requests.map(([url, options, counter]) => _fetch(url, token, options)
        .then(() => ({ ok: true, options }))
        .catch(() => ({ ok: false, request: [url, options, counter + 1]}))))
      .then(values => {
        let m = [];
        values.forEach(val => {
          if (val.ok) {
            let v = JSON.parse(val.options.body);
            m.push(v);
          } else {
            const [_url, _options, _counter] = val.request;
            // check the retryLimit before adding it to the queue
            if (_counter < settings.retryLimit) {
              queue.push([_url, _options, _counter]);
            }
          }
        });
        isRunning = false;
        if (m.length > 0 && !!route_id && route_id >= 0) {
          m.forEach(({ lat, lng, positive, description }) => {
            dispatch({
              type: "ROUTES_ADD_SITUATION",
              payload: {id: route_id, situation: { lat, lng, positive, description }}
            });
          });
          dialog(`${m.length} melding(en) sucessvol opgeslagen`);
        }
        serializeQueue();
      });
  }

  if (hasLocalStorage) {
    populateQueue();
  }
  return self;
}

export const gotoLogin = () => (window.location.href = '/login');
export const gotoLogout = () => (window.location.href = '/admin-panel/logout');

// predefined requests