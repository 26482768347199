export const NOT_ASKED = 0;
export const LOADING = 1;
export const SUCCESS = 2;
export const ERROR = 3;

export const setInit = () => {
  return {
    state: NOT_ASKED,
    value: null
  };
};

export const setLoading = () => {
  return {
    state: LOADING,
    value: null
  };
};

export const setValue = value => {
  return {
    state: SUCCESS,
    value: value
  };
};

export const setError = err => {
  return {
    state: ERROR,
    value: err
  };
};
