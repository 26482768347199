import React, { useState } from "react";
import useGlobalState from "hooks/useState";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { isProduction } from "../shared/constants";

const useStyles = makeStyles({
  root: {
    color: "white"
  }
});

const LogoutButton = () => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [, dispatch] = useGlobalState();
  const history = useHistory();
  const classes = useStyles();

  const handleOnClose = () => {
    setAnchorElement(null);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    !isProduction
      ? history.push("/login")
      : (window.location.href = process.env.REACT_APP_LOGOUT_URL);
  };

  const handleOnOpen = event => {
    setAnchorElement(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={handleOnOpen} className={classes.root}>
        <PersonIcon />
      </IconButton>
      <Menu
        id="logout-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleOnClose}
      >
        <MenuItem
          onClick={() => {
            window.location.href = process.env.REACT_APP_ADMIN_PANEL_URL;
          }}
        >
          Naar Beheeromgeving
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOnClose();
            handleLogout();
          }}
        >
          Uitloggen
        </MenuItem>
      </Menu>
    </>
  );
};

export default LogoutButton;
